<template>
    <BaseScene 
        name="stage-pick-perspective" 
        :background="ResourceManager.Get('scenes.stagepickperspective')"
        @leave="OnLeaveScene" 
        @enter="OnEnterScene"         
    >
        <SceneTrigger scene="stage-closed-curtain" x="-20" y="0"/>
        <SceneTrigger scene="stage-actor-view" x="20" y="0"/>
        <SceneTrigger scene="changing-room" x="14" y="-9" :sound="ResourceManager.Get('shorts.footstepslong')" :delay="500"/>

        <img :src="ResourceManager.Get('misc.backstage')" class="backstage">
    </BaseScene>
</template>

<script>
import BaseScene from '../BaseScene.vue'
import SceneTrigger from '../triggers/SceneTrigger.vue';
import { Howl } from 'howler';
import ResourceManager from "@/classes/resource-manager";

export default {
    components: {
        BaseScene,
        SceneTrigger
    },
    mounted() {

    },
    data() {
        return {
            ResourceManager,
            bgm: null,
            bgmShutdown: null
        }
    },
    methods: {
        OnEnterScene() {
            if (this.bgmShutdown) {
                clearTimeout(this.bgmShutdown);
                this.bgmShutdown = null;
            }

            if (!this.bgm) {
                this.bgm = new Howl({
                    src: [ResourceManager.Get('ambience.stagehall')],
                    loop: true,
                    volume: 1
                });
            }
    
            if (!this.bgm.playing()) {
                this.bgm.play();
            }
        },
        OnLeaveScene() {
            this.bgm.fade(1.0, 0.0, 2000);
            this.bgmShutdown = setTimeout(() => {
                this.bgm.stop();
                this.bgm.volume(1);
            }, 3000);
        }
    }    
}
</script>

<style lang="scss">
.scene-stage-pick-perspective {
    .backstage {
        position: absolute;
        left: 50%;
        top: 50%;
        @include xy-ar(9, -9);
        transform: translate(-50%, -50%);
        user-select: none;
    }
}
</style>